//import { mapState, mapActions } from 'vuex';
import AuthService from "../../service/AuthService";
import swal from 'sweetalert2'
import router from "../../routes/router";
export const state = {
    token: null,
    loading: false,
    response: {},
    userInfo: {},
    errors: null,
    success: null,
    bankInfo: {},
    logo: 'https://coregem-imgs.s3.amazonaws.com/logo_header_removebg.png',


}
export const getters = {
    getResponse: state => {
        // console.log("getter getTok")
        return state.response
    },
    getUserInfo: state => {
        // console.log("getter getTok")
        return state.userInfo
    },
    getUserBankInfo: state => {
        // console.log("getter getTok")
        return state.bankInfo
    },
    getTok: state => {
        // console.log("getter getTok")
        return state.token
    },
    getUserToken: state => {
        // console.log("getter getTok")
        return localStorage.getItem('token')
    },
}
export const mutations = {
    updateResponse: (state, payload) => {
        state.response = payload
    },
    updateAuthLoading: (state, payload) => {
        state.loading = payload
    },
    updateAuthToken: (state, payload) => {
        // console.log("mutation updateAuthToken ==>>", payload)
        state.token = payload
    },
    updateUserInfo: (state, payload) => {
        console.log("Userree");
        state.userInfo = payload;
    },
    updateError: (state, payload) => {
        state.errors = payload
    },
    updateSuccess: (state, payload) => {
        state.success = payload
    },
    updateUserBankInfo: (state, payload) => {
        state.logo = payload.bankLogo;
        state.bankInfo = payload
    },

}
export const actions = {
    logon: ({ commit, dispatch, rootState }, payload) => {
        commit("updateAuthLoading", true)
        commit("updateResponse", {});
        commit("updateError", null);
        commit("updateSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return AuthService.callLogonApi(payload)
            .then(response => {
                let responseData = response.data;
                commit("updateResponse", responseData);
                if (responseData.responseCode === "00") {
                    localStorage.token = responseData.userToken;
                    localStorage.username = responseData.username;
                    commit("updateAuthToken", responseData.userToken);
                    commit("updateUserInfo", responseData);
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    //commit("updateSuccess", responseData.responseMessage);
                    //router.push('alternative');
                    //router.push('dashboard');
                    //commit("updateAuthLoading", false);

                    AuthService.callReadBankReadSingleApi({
                            bankCode: payload.userBankCode,
                            source: "WEB"
                        })
                        .then(response1 => {
                            //commit("updateCustomerLoading", !true)
                            let responseData1 = response1.data;
                            if (responseData1.responseCode === "00") {
                                //commit("updateResponse", responseData1);
                                console.log(responseData1.responseMessage);
                                commit("updateSuccess", responseData1.responseMessage);
                                commit("updateUserBankInfo", responseData1);
                                router.push({ name: 'All User' });
                                commit("updateAuthLoading", false);
                                //commit("updateAuthLoading", false);
                                // commit("updateCheckerSuccess", responseData1.responseMessage);
                                //commit("updateCheckerResponse", responseData1);
                                //router.push('alternative');
                                //commit("updateCheckerLoading", false);

                            } else {
                                commit("updateAuthLoading", false);
                                commit("updateError", responseData1.responseMessage);
                                console.log(responseData1.responseMessage);
                                //swal('Error!', responseData.responseMessage, 'error').then();
                            }

                        })
                        .catch(error => {
                            commit("updateCheckerLoading", false)
                            alert(error)
                                // throw error;
                        });



                } else {
                    commit("updateAuthLoading", false);
                    commit("updateError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateAuthLoading", false);
                commit("updateError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    logOut: ({ commit, dispatch, rootState }, payload) => {
        commit("updateAuthLoading", true)
            //commit("updateResponse", {});
        commit("updateError", null);
        commit("updateSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return AuthService.callLogOutApi(payload)
            .then(response => {
                let responseData = response.data;
                commit("updateResponse", responseData);
                if (responseData.responseCode === "00") {
                    localStorage.token = '';
                    localStorage.username = '';
                    commit("updateAuthToken", '');
                    commit("updateUserInfo", {});
                    // eslint-disable-next-line no-undef
                    //store.dispatch("bankList", { request: "banklist" }, { root: true })
                    /*   // eslint-disable-next-line no-unused-vars
                       .then(res => {*/
                    //dispatch("bankList", { request: "banklist" }, { root: false });
                    commit("updateAuthLoading", false);
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    commit("updateSuccess", responseData.responseMessage);
                    //router.push('alternative');
                    router.push('/');

                } else {
                    commit("updateAuthLoading", false);
                    commit("updateError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateAuthLoading", false);
                commit("updateError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};