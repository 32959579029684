import DashboardLayout from '@/views/Layout/DashboardLayout.vue';
import AuthLayout from '@/views/Pages/AuthLayout.vue';
// GeneralViews
import NotFound from '@/views/GeneralViews/NotFoundPage.vue';

// Calendar
const Calendar = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Calendar/Calendar.vue');
// Charts
const Charts = () =>
    import ( /* webpackChunkName: "dashboard" */ '@/views/Charts.vue');

// Components pages
const Buttons = () =>
    import ( /* webpackChunkName: "components" */ '@/views/Components/Buttons.vue');
const Cards = () =>
    import ( /* webpackChunkName: "components" */ '@/views/Components/Cards.vue');
const GridSystem = () =>
    import ( /* webpackChunkName: "components" */ '@/views/Components/GridSystem.vue');
const Notifications = () =>
    import ( /* webpackChunkName: "components" */ '@/views/Components/Notifications.vue');
const Icons = () =>
    import ( /* webpackChunkName: "components" */ '@/views/Components/Icons.vue');
const Typography = () =>
    import ( /* webpackChunkName: "components" */ '@/views/Components/Typography.vue');

// Dashboard pages
const Dashboard = () =>
    import ( /* webpackChunkName: "dashboard" */ '@/views/Dashboard/Dashboard.vue');
const AlternativeDashboard = () =>
    import ( /* webpackChunkName: "dashboard" */ '@/views/Dashboard/AlternativeDashboard.vue');
const Widgets = () =>
    import ( /* webpackChunkName: "dashboard" */ '@/views/Widgets.vue');

// Forms pages
const FormElements = () =>
    import ( /* webpackChunkName: "forms" */ '@/views/Forms/FormElements.vue');
const FormComponents = () =>
    import ( /* webpackChunkName: "forms" */ '@/views/Forms/FormComponents.vue');
const FormValidation = () =>
    import ( /* webpackChunkName: "forms" */ '@/views/Forms/FormValidation.vue');

// Role pages
const CreateRole = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Role/CreateRole.vue');
const AllRole = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Role/AllRole.vue');
const EditRole = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Role/EditRole.vue');

// User pages
const CreateUser = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/User/CreateUser.vue');
const AllUser = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/User/AllUser.vue');
const UsersCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/User/UsersCheckersQueue.vue');

// Branch pages
const CreateBranch = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Branch/CreateBranch.vue');
const AllBranch = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Branch/AllBranch.vue');
const BranchCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Branch/BranchCheckersQueue.vue');


// Customer pages
const CreateCustomer = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/CreateCustomer.vue');
const CreateAccount = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/CreateAccount.vue');
const ViewCustomer = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/ViewCustomer.vue');
const ViewCustomerAccounts = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/ViewCustomerAccounts.vue');
const CustomerCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/CustomerCheckersQueue.vue');
const SubAccountCustomerCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/SubAccountCustomerCheckersQueue.vue');
const CreateCustomerUpload = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/CreateCustomerUpload.vue');
const RequestAccountStatement = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Customer/RequestAccountStatement.vue');




// InternalAccount pages
const CreateInternalAccount = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/InternalAccount/CreateInternalAccount.vue');
const AllInternalAccount = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/InternalAccount/AllInternalAccount.vue');
const InternalAccountCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/InternalAccount/InternalAccountCheckersQueue.vue');

// Bank pages
const BankLogoUpload = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Bank/BankLogo.vue');

// Account pages
const AccountTab = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Account/AccountTab.vue');

// Product pages
const SelectProductType = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/SelectProductType.vue');
const SelectProductTypeView = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/SelectProductTypeView.vue');
const CreateProduct = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/CreateProduct.vue');
const ProductView = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/ProductView.vue');
const CreateAccountEntry = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/CreateAccountEntry.vue');
const AllAccountEntry = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/AllAccountEntry.vue');

const AssignEntries = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/AssignEntries.vue');
const AddAssignEntries = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/AddAssignEntries.vue');
const AllProducts = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Product/AllProducts.vue');

// SwitchTransaction
const NIP = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/SwitchTransaction/NIP.vue');

// Settings
const Settings = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Settings/Settings.vue');

// Fund Transfer
const CustomerBalanceEnquiry = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/CustomerBalanceEnquiry.vue');
const TransferFunds = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/FundTransfer.vue');
const FundTransferCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/FundTransferCheckersQueue.vue');

const CashTransfer = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/CashTransfer.vue');
const CashWithDrawal = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/CashWithDrawal.vue');
const CashWithDrawalCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/CashWithDrawalCheckersQueue.vue');
const CashDepositCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/CashDepositCheckersQueue.vue');
const CashTransactionCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transfer/CashTransactionCheckersQueue.vue');





// Transaction
const CustomerTransaction = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transaction/CustomerTransaction.vue');
const TransactionEnquiry = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transaction/TransactionEnquiry.vue');
const TellerTransactionHistory = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transaction/TellerTransactionHistory.vue');
const ManualReversal = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Transaction/ManualReversal.vue');

const AuthorizeCheckerQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/CheckerAlert/AuthorizeCheckerQueue.vue');



//Teller
const TellerCreate = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Teller/TellerCreate.vue');
const TellerAll = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Teller/TellerAll.vue');
const TellerCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Teller/TellerCheckersQueue.vue');

//Till
const TillCreate = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Till/TillCreate.vue');
const TillAll = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Till/TillAll.vue');
const TillCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Till/TillCheckersQueue.vue');

//Vault
const VaultCreate = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Vault/VaultCreate.vue');
const VaultAll = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Vault/VaultAll.vue');
const VaultCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Vault/VaultCheckersQueue.vue');

//GLLevel1
const GLLevel1Create = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/GLLevel/GLLevel1Create.vue');
const GLLevel1View = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/GLLevel/GLLevel1View.vue');
const GLLevel1CheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/GLLevel/GLLevel1CheckersQueue.vue');

//GLLevel2
const GLLevel2Create = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/GLLevel/GLLevel2Create.vue');
const GLLevel2View = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/GLLevel/GLLevel2View.vue');
const GLLevel2CheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/GLLevel/GLLevel2CheckersQueue.vue');

//Journal
const JournalOpen = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Journal/JournalOpen.vue');
const JournalView = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Journal/JournalView.vue');
const AddEntryToBatch = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Journal/AddEntryToBatch.vue');
const EditBatchEntry = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Journal/EditBatchEntry.vue');
const JournalCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Journal/JournalCheckersQueue.vue');

//Report
const EOD = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/EOD.vue');
const EODItem = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/EODItem.vue');
const EOM = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/EOM.vue');
const EOMItem = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/EOMItem.vue');

const ReportCreate = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/ReportCreate.vue');
const ReportView = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/ReportView.vue');
const ReportCheckersQueue = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/ReportCheckersQueue.vue');
const QueryReport = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/QueryReport.vue');
const SingleReport = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/SingleReport.vue');
const SingleReportQuery = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Report/SingleReportQuery.vue');

////Loan
const BookLoan = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Loan/BookLoan.vue');
const ViewLoans = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Loan/ViewLoans.vue');
const AmortizationSchedule = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Loan/AmortizationSchedule.vue');


// Maps pages
const GoogleMaps = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Maps/GoogleMaps.vue');
const VectorMaps = () =>
    import ( /* webpackChunkName: "extra" */ '@/views/Maps/VectorMaps.vue');

// Pages
const User = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/UserProfile.vue');
const Pricing = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/Pricing.vue');
const TimeLine = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/TimeLinePage.vue');
const Login = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/UserPages/Login.vue');
const Home = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/Home.vue');
const Register = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/Register.vue');
const Lock = () =>
    import ( /* webpackChunkName: "pages" */ '@/views/Pages/Lock.vue');

// TableList pages
const RegularTables = () =>
    import ( /* webpackChunkName: "tables" */ '@/views/Tables/RegularTables.vue');
const SortableTables = () =>
    import ( /* webpackChunkName: "tables" */ '@/views/Tables/SortableTables.vue');
const PaginatedTables = () =>
    import ( /* webpackChunkName: "tables" */ '@/views/Tables/PaginatedTables.vue');




const routes = [{
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/dashboard',
        component: DashboardLayout,
        //redirect: '/dashboard',
        meta: { requiresAuth: true },
        name: 'Dashboard layout',
        children: [
            /*{
                            path: '/dashboard',
                            name: 'Dashboard',
                            component: Dashboard
                        },*/
            {
                path: '/dashboard',
                name: 'Dashboard',
                component: AlternativeDashboard,
                meta: {
                    navbarType: 'light'
                }
            },
            {
                path: '/calendar',
                name: 'Calendar',
                component: Calendar
            },
            {
                path: '/charts',
                name: 'Charts',
                component: Charts
            },
            {
                path: '/widgets',
                name: 'Widgets',
                component: Widgets
            }
        ]
    },
    {
        path: '/role',
        component: DashboardLayout,
        name: 'Role',
        meta: { requiresAuth: true },
        redirect: '/role/create-role',
        children: [{
                path: 'create',
                name: 'Create Role',
                component: CreateRole
            },
            {
                path: 'all',
                name: 'All Role',
                component: AllRole
            },
            {
                path: 'edit',
                name: 'Edit Role',
                component: EditRole
            }
        ]
    },
    {
        path: '/user',
        component: DashboardLayout,
        name: 'User',
        meta: { requiresAuth: true },
        redirect: '/user/create-user',
        children: [{
                path: 'create',
                name: 'Create User',
                component: CreateUser
            },
            {
                path: 'all',
                name: 'All User',
                component: AllUser
            },
            {
                path: 'users-checkers-queue',
                name: 'User Checkers Queue',
                component: UsersCheckersQueue
            }
        ]
    },

    {
        path: '/branch',
        component: DashboardLayout,
        name: 'Branch',
        meta: { requiresAuth: true },
        redirect: '/branch/create',
        children: [{
                path: 'create',
                name: 'Create Branch',
                component: CreateBranch
            },
            {
                path: 'all',
                name: 'All Branch',
                component: AllBranch
            },
            {
                path: 'checkers-queue',
                name: 'Branch Checkers Queue',
                component: BranchCheckersQueue
            }
        ]
    },

    {
        path: '/account',
        component: DashboardLayout,
        name: 'Branch',
        meta: { requiresAuth: true },
        redirect: '/account/accounts',
        children: [{
                path: 'accounts',
                name: 'Accounts',
                component: AccountTab
            },

        ]
    },

    {
        path: '/customer',
        component: DashboardLayout,
        name: 'Customer',
        meta: { requiresAuth: true },
        redirect: '/Customer/create-customer',
        children: [{
                path: 'create-account',
                name: 'Create Account',
                component: CreateAccount
            },
            {
                path: 'create-customer',
                name: 'Create Customer',
                component: CreateCustomer
            },
            {
                path: 'all',
                name: 'View Customer',
                component: ViewCustomer
            },
            {
                path: 'view-accounts',
                name: 'View Accounts',
                component: ViewCustomerAccounts
            },
            {
                path: 'checkers-queue',
                name: 'Customer Checkers Queue',
                component: CustomerCheckersQueue
            },
            {
                path: 'sub-account-checkers-queue',
                name: 'Sub Account Checkers Queue',
                component: SubAccountCustomerCheckersQueue
            },
            {
                path: 'create-upload',
                name: 'Create Customer Upload',
                component: CreateCustomerUpload
            },
            {
                path: 'account-statement',
                name: 'Request Account Statement',
                component: RequestAccountStatement
            },
        ]
    },

    {
        path: '/internal-account',
        component: DashboardLayout,
        name: 'Internal Account',
        meta: { requiresAuth: true },
        redirect: '/internal-account/create',
        children: [{
                path: 'create',
                name: 'Create Internal Account',
                component: CreateInternalAccount
            },
            {
                path: 'all',
                name: 'All Internal Account',
                component: AllInternalAccount
            },
            {
                path: 'checkers-queue',
                name: 'Internal Account Checkers Queue',
                component: InternalAccountCheckersQueue
            }
        ]
    },

    {
        path: '/bank',
        component: DashboardLayout,
        name: 'Bank',
        meta: { requiresAuth: true },
        redirect: '/bank/upload-logo',
        children: [{
            path: 'upload-logo',
            name: 'Upload Logo',
            component: BankLogoUpload
        }]
    },

    {
        path: '/product',
        component: DashboardLayout,
        name: 'Product',
        meta: { requiresAuth: true },
        redirect: '/product/select-product-type',
        children: [{
                path: 'select-product-type',
                name: 'Select Product Type',
                component: SelectProductType
            },
            {
                path: 'view-products',
                name: 'View Products',
                component: ProductView
            },
            {
                path: 'select-product-type-view',
                name: 'Select Product Type View',
                component: SelectProductTypeView
            },
            {
                path: 'create-product',
                name: 'Create Product',
                component: CreateProduct
            },
            {
                path: 'accounting-entry/all',
                name: 'All Account Entry',
                component: AllAccountEntry
            },
            {
                path: 'accounting-entry/create',
                name: 'CreateAccountEntry',
                component: CreateAccountEntry
            },
            {
                path: 'accounting-entry/assign-entriesaa',
                name: 'AssignEntries',
                component: AssignEntries
            },
            {
                path: 'accounting-entry/add-assign-entriesaaa',
                name: 'AddAssignEntries',
                component: AddAssignEntries
            },
            {
                path: 'all',
                name: 'AllProducts',
                component: AllProducts
            },
        ]
    },
    {
        path: '/settings',
        component: DashboardLayout,
        name: 'Settings',
        meta: { requiresAuth: true },
        redirect: '/settings/all',
        children: [{
                path: 'all',
                name: 'Settings',
                component: Settings
            },
            {
                path: 'accounting-entry/all',
                name: 'All Account Entry',
                component: AllAccountEntry
            },
            {
                path: 'accounting-entry/create',
                name: 'CreateAccountEntry',
                component: CreateAccountEntry
            },
            {
                path: 'accounting-entry/assign-entries',
                name: 'AssignEntries',
                component: AssignEntries
            },
            {
                path: 'accounting-entry/add-assign-entries',
                name: 'AddAssignEntries',
                component: AddAssignEntries
            },
        ]
    },

    {
        path: '/switch-transactions',
        component: DashboardLayout,
        name: 'SwitchTransactions',
        meta: { requiresAuth: true },
        redirect: '/switch-transactions/all-nip-inward',
        children: [{
                path: 'all-nip-inward',
                name: 'AllNipInward',
                component: NIP
            },

        ]
    },

    {
        path: '/funds-transfer',
        component: DashboardLayout,
        name: 'Transfer',
        meta: { requiresAuth: true },
        redirect: '/funds-transfer/customer-balance-enquiry',
        children: [{
                path: 'customer-balance-enquiry',
                name: 'CustomerBalanceEnquiry',
                component: CustomerBalanceEnquiry
            },
            {
                path: 'transfer-funds',
                name: 'TransferFunds',
                component: TransferFunds
            },
            {
                path: 'ft-checkers-queue',
                name: 'FundTransferCheckersQueue',
                component: FundTransferCheckersQueue
            },

            {
                path: 'cash-transaction',
                name: 'CashTransfer',
                component: CashTransfer
            },
            {
                path: 'cash-withdrawals',
                name: 'CashWithDrawal',
                component: CashWithDrawal
            },
            {
                path: 'cw-checkers-queue',
                name: 'CashWithDrawalCheckersQueue',
                component: CashWithDrawalCheckersQueue
            },
            {
                path: 'cd-checkers-queue',
                name: 'CashDepositCheckersQueue',
                component: CashDepositCheckersQueue
            },
            {
                path: 'ct-checkers-queue',
                name: 'CashTransactionCheckersQueue',
                component: CashTransactionCheckersQueue
            },

        ]
    },

    {
        path: '/transaction',
        component: DashboardLayout,
        name: 'Transactions',
        meta: { requiresAuth: true },
        redirect: '/transaction/customer-transactions',
        children: [{
                path: 'customer-transactions',
                name: 'CustomerTransaction',
                component: CustomerTransaction
            },
            {
                path: 'transaction-enquiry',
                name: 'TransactionEnquiry',
                component: TransactionEnquiry
            },
            {
                path: 'teller-transaction-history',
                name: 'TellerTransactionHistory',
                component: TellerTransactionHistory
            },
            {
                path: 'manual-reversal',
                name: 'ManualReversal',
                component: ManualReversal
            },



        ]
    },
    {
        path: '/teller',
        component: DashboardLayout,
        name: 'Teller',
        meta: { requiresAuth: true },
        redirect: '/teller/create',
        children: [{
                path: 'create',
                name: 'TellerCreate',
                component: TellerCreate
            },
            {
                path: 'all',
                name: 'TellerAll',
                component: TellerAll
            },
            {
                path: 'checkers-queue',
                name: 'TellerCheckersQueue',
                component: TellerCheckersQueue
            }
        ]
    },

    {
        path: '/till',
        component: DashboardLayout,
        name: 'Till',
        meta: { requiresAuth: true },
        redirect: '/till/create',
        children: [{
                path: 'create',
                name: 'TillCreate',
                component: TillCreate
            },
            {
                path: 'all',
                name: 'TillAll',
                component: TillAll
            },
            {
                path: 'checkers-queue',
                name: 'TillCheckersQueue',
                component: TillCheckersQueue
            }
        ]
    },

    {
        path: '/vault',
        component: DashboardLayout,
        name: 'Vault',
        meta: { requiresAuth: true },
        redirect: '/vault/create',
        children: [{
                path: 'create',
                name: 'VaultCreate',
                component: VaultCreate
            },
            {
                path: 'all',
                name: 'VaultAll',
                component: VaultAll
            },
            {
                path: 'checkers-queue',
                name: 'VaultCheckersQueue',
                component: VaultCheckersQueue
            }
        ]
    },


    {
        path: '/glLevel1',
        component: DashboardLayout,
        name: 'GLLevel1',
        meta: { requiresAuth: true },
        redirect: '/glLevel1/create',
        children: [{
                path: 'create',
                name: 'GLLevel1Create',
                component: GLLevel1Create
            },
            {
                path: 'view',
                name: 'GLLevel1View',
                component: GLLevel1View
            },
            {
                path: 'checkers-queue',
                name: 'GLLevel1CheckersQueue',
                component: GLLevel1CheckersQueue
            }
        ]
    },


    {
        path: '/glLevel2',
        component: DashboardLayout,
        name: 'GLLevel2',
        meta: { requiresAuth: true },
        redirect: '/glLevel2/create',
        children: [{
                path: 'create',
                name: 'GLLLevel2Create',
                component: GLLevel2Create
            },
            {
                path: 'view',
                name: 'GLLevel2View',
                component: GLLevel2View
            },
            {
                path: 'checkers-queue',
                name: 'GLLevel2CheckersQueue',
                component: GLLevel2CheckersQueue
            }
        ]
    },

    {
        path: '/journal',
        component: DashboardLayout,
        name: 'Journal',
        meta: { requiresAuth: true },
        redirect: '/journal/open-batch',
        children: [{
                path: 'open-batch',
                name: 'JournalOpen',
                component: JournalOpen
            },
            {
                path: 'view',
                name: 'JournalView',
                component: JournalView
            },
            {
                path: 'add-entry-to-batch',
                name: 'AddEntryToBatch',
                component: AddEntryToBatch
            },
            {
                path: 'edit-batch-entry',
                name: 'EditBatchEntry',
                component: EditBatchEntry
            },
            {
                path: 'checkers-queue',
                name: 'JournalCheckersQueue',
                component: JournalCheckersQueue
            }
        ]
    },
    {
        path: '/report',
        component: DashboardLayout,
        name: 'Report',
        meta: { requiresAuth: true },
        redirect: '/report/create',
        children: [{
                path: 'eod/all',
                name: 'EOD',
                component: EOD
            },
            {
                path: 'eod/items',
                name: 'EODItems',
                component: EODItem
            },
            {
                path: 'eom/all',
                name: 'EOM',
                component: EOM
            },
            {
                path: 'eom/items',
                name: 'EOMItems',
                component: EOMItem
            },
            {
                path: 'create',
                name: 'ReportCreate',
                component: ReportCreate
            },
            {
                path: 'all',
                name: 'ReportView',
                component: ReportView
            },
            {
                path: 'checkers-queue',
                name: 'ReportCheckersQueue',
                component: ReportCheckersQueue
            },
            {
                path: 'query-report',
                name: 'QueryReport',
                component: QueryReport
            },
            {
                path: 'single-report',
                name: 'SingleReport',
                component: SingleReport
            },

            {
                path: 'single-report-query',
                name: 'SingleReportQuery',
                component: SingleReportQuery
            },

        ]
    },
    {
        path: '/loan',
        component: DashboardLayout,
        name: 'Loan',
        meta: { requiresAuth: true },
        redirect: '/loan/book-loan',
        children: [{
                path: 'book-loan',
                name: 'BookLoan',
                component: BookLoan
            },
            {
                path: 'all',
                name: 'ViewLoans',
                component: ViewLoans
            },
            {
                path: 'amortization-schedule',
                name: 'AmortizationSchedule',
                component: AmortizationSchedule
            }
        ]
    },
    {
        path: '/checkers-alert',
        component: DashboardLayout,
        name: 'CheckerAlert',
        meta: { requiresAuth: true },
        redirect: '/checkers-alert/authorize-checker-queue',
        children: [{
                path: 'authorize-checker-queue',
                name: 'AuthorizeCheckerQueue',
                component: AuthorizeCheckerQueue
            },

        ]
    },





    {
        path: '/',
        component: AuthLayout,
        name: 'Authentication',
        children: [{
                path: '/home',
                name: 'Home',
                component: Home,
                meta: {
                    noBodyBackground: true
                }
            },

            {
                path: '/register',
                name: 'Register',
                component: Register
            },
            {
                path: '/pricing',
                name: 'Pricing',
                component: Pricing
            },
            {
                path: '/lock',
                name: 'Lock',
                component: Lock
            },
            { path: '*', component: NotFound }
        ]
    }
];


export default routes;