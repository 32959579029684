//import { mapState, mapActions } from 'vuex';
import PrivilageService from "../../service/PrivilageService";
import swal from 'sweetalert2'
import router from "../../routes/router";
export const state = {
    token: null,
    privilage_loading: false,
    privilage_response: {},
    privilage: [],
    privilage_errors: null,
    privilage_success: null,

}
export const getters = {
    getPrivilageResponse: state => {
        // console.log("getter getTok")
        return state.privilage_response
    },

}
export const mutations = {
    updatePrivilageResponse: (state, payload) => {
        state.privilage_response = payload
    },
    updatePrivilage: (state, payload) => {
        state.privilage = payload;
    },
    updatePrivilageLoading: (state, payload) => {
        state.privilage_loading = payload
    },
    updatePrivilageError: (state, payload) => {
        state.privilage_errors = payload
    },
    updatePrivilageSuccess: (state, payload) => {
        state.privilage_success = payload
    },

}
export const actions = {
    privilageRead: ({ commit, dispatch, rootState }, payload) => {
        commit("updatePrivilageLoading", true)
            // commit("updatePrivilageResponse", {});
        commit("updatePrivilageError", null);
        //commit("updatePrivilageSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return PrivilageService.callPrivilageReadAPI(payload)
            .then(response => {
                let responseData = response.data;
                commit("updatePrivilageResponse", responseData);
                if (responseData.responseCode === "00") {
                    commit("updatePrivilage", responseData.data);
                    console.log(">>>>>>>>>>>>>>>|||||||||||||||<<<<<<<<<<<<<<<<<<<");
                    //commit("updateAuthLoading", false);
                    // commit("updatePrivilageSuccess", responseData.responseMessage);
                    //router.push('alternative');
                    commit("updatePrivilageLoading", false);
                } else {
                    commit("updatePrivilageLoading", false);
                    commit("updatePrivilageError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updatePrivilageLoading", false);
                commit("updatePrivilageError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};