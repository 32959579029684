var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('notifications'),_c('side-bar',[_c('template',{slot:"links"},[_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Management")]),_c('sidebar-item',{attrs:{"link":{ name: 'User',
                        icon: 'ni ni-support-16 text-primary',
                        path: '/user/all' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Role',
                        icon: 'ni ni-support-16 text-primary',
                        path: '/role/all' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Branch',
                        icon: 'ni ni-support-16 text-primary',   
                        path: '/branch/all'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Account',
                        icon: 'ni ni-support-16 text-primary',   
                        path: '/account/accounts'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Customer',
                        icon: 'ni ni-support-16 text-primary',   
                        path: '/customer/all'}}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Products")]),_c('sidebar-item',{attrs:{"link":{ name: 'Product',
                        icon: 'ni ni-support-16 text-primary',
                        path: '/product/all' }}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Settings")]),_c('sidebar-item',{attrs:{"link":{ name: 'Setting',
                        icon: 'ni ni-support-16 text-primary',
                        path: '/settings/all' }}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Maintenance")]),_c('sidebar-item',{attrs:{"link":{
                        name: 'Internal Accounts',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Internal Account', path: '/internal-account/create' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'All Internal Account', path: '/internal-account/all' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Bank',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Upload Logo',path:'/bank/upload-logo'}}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Branch',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Branch',path:'/branch/create'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'All Branch', path: '/branch/all' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Customer',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Account',path:'/customer/create-account'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Create Customer',path:'/customer/create-customer'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Customer', path: '/customer/view' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Customer Accounts', path: '/customer/view-accounts' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Create Customer Upload',path:'/customer/create-upload'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Request Account Statement',path:'/customer/account-statement'}}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("System Operations")]),_c('sidebar-item',{attrs:{"link":{
                        name: 'Reports',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Report', path: '/report/create' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Report', path: '/report/all' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Loan Processing',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Book Loan', path: '/loan/book-loan' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Loans', path: '/loan/all' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'System Maintenance',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'EOD Run', path: '/report/eod/all' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'EOM Run', path: '/report/eom/all' }}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Products")]),_c('sidebar-item',{attrs:{"link":{
                        name: 'Product',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Product', path: '/product/select-product-type' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Products', path: '/product/select-product-type-view' }}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Transaction")]),_c('sidebar-item',{attrs:{"link":{
                        name: 'Transaction',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Customer Transactions',path:'/transaction/customer-transactions'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Transaction Enquiry',path:'/transaction/transaction-enquiry'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Fund Transfer',path:'/funds-transfer/transfer-funds'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'GL Fund Transfer',path:'/funds-transfer/cash-transaction'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Cash Transactions',path:'/funds-transfer/cash-withdrawals'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Transaction History',path:'/transaction/teller-transaction-history'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'Manual Reversal',path:'/transaction/manual-reversal'}}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Teller',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Teller',path:'/teller/create'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'List Tellers', path: '/teller/all' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Till',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Till',path:'/till/create'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Till', path: '/till/all' }}})],1),_c('sidebar-item',{attrs:{"link":{
                        name: 'Vault',
                        icon: 'ni ni-single-02 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Create Vault',path:'/vault/create'}}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Vault', path: '/vault/all' }}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Charts of Accounts")]),_c('sidebar-item',{attrs:{"link":{
                        name: 'Chart of Account',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Level 1 GL', path: '/glLevel1/view' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'Level 2 GL', path: '/glLevel2/view' }}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Multiple Transactions")]),_c('sidebar-item',{attrs:{"link":{
                        name: 'Journal',
                        icon: 'ni ni-support-16 text-primary'
                      }}},[_c('sidebar-item',{attrs:{"link":{ name: 'Open Batch', path: '/journal/open-batch' }}}),_c('sidebar-item',{attrs:{"link":{ name: 'View Batches', path: '/journal/view' }}})],1),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"},[_vm._v("Queues & Alerts")]),_c('sidebar-item',{attrs:{"link":{ name: 'Authorize Checkers Queue',
          icon: 'ni ni-support-16 text-primary',
           path: '/checkers-alert/authorize-checker-queue' }}}),_c('h6',{staticClass:"navbar-heading pt-3 pb-1 text-muted"})],1)],2),_c('div',{staticClass:"main-content"},[_c('dashboard-navbar',{attrs:{"type":_vm.$route.meta.navbarType}}),_c('div',{on:{"click":function($event){return _vm.$sidebar.displaySidebar(false)}}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1)],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }