<template>
  <div  v-show="show">
      <div class='container-bar'>
  <div class='bar'>
  </div>
</div>
    </div>
</template>
<script>
  export default {
    name: "LoadingProgress",
    props: {
      show: Boolean
      }
  }
</script>
<style lang="scss" scoped>
:root {
  --colour1: #f1c40f;
  --colour2: #2c3e50;
  --speed: 4s;
}

@keyframes slide {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: 113px;
  }
}

.container-bar {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .bar {
    min-height: 3px;
    border-radius: 10px;
    width: calc(113px * 4);
    @media only screen and (max-width: 450px) {
      width: calc(113px * 3);
    }
    @media only screen and (max-width: 350px) {
      width: calc(113px * 2);
    }
    box-shadow: 0px 10px 13px -6px rgba(44, 62, 80, 1);
    background-color: var(--colour2);
    background-image: repeating-linear-gradient(
      45deg,
      transparent,
      transparent 40px,
      var(--colour1) 40px,
      var(--colour1) 80px
    );

    animation: slide var(--speed) linear infinite;
    will-change: background-position;
  }
}
</style>
 
