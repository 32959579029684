import axios from "axios";
import { qgBaseUrl } from "../app.config";
import store from "../store/store";
// import store from "@/store/store";

const apiClient = axios.create({
    baseURL: qgBaseUrl,
    withCredentials: false,
    headers: {
        //Accept: "application/json",
        //"Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
    }
});

const apiClient2 = axios.create({
    baseURL: qgBaseUrl,
    withCredentials: false,
    headers: {
        //Accept: "application/json",
        "Content-Type": "application/json",
        //"Access-Control-Allow-Origin": "*",
    }
});
// https://cors-anywhere.herokuapp.com


apiClient.interceptors.request.use(config => {
    // console.log("Axios Interceptor Config ==>", config);
    const token = store.getters.getToken;
    const tok = store.getters.getUserToken; //["auth/getUserToken"];
    //console.log(token);
    console.log("////////////////////Account");
    //console.log(token);
    //console.log(tok)
    // console.log("INTERCEPTOR TOKEN", token)
    // console.log("INTERCEPTOR  TOK ==>", tok)
    //config.headers.Authorization = (token == null) ? tok : token;
    config.headers.Authorization = token;
    return config
})

export default {
    callAccountReadAPI(payload) {
        return apiClient.post("accountRead", payload);
    },
    callAccountReadSingleAPI(payload) {
        return apiClient.post("accountReadSingle", payload);
    },
    callAccountCreateAPI(payload) {
        return apiClient.post("accountCreate", payload);
    },
    callAccountUpdateAPI(payload) {
        return apiClient.post("accountUpdate", payload);
    },
    callCreateTerminalAPI(payload) {
        return apiClient.post("createTerminal", payload);
    },
    callAccountCheckerAPI(payload) {
        return apiClient.post("accountChecker", payload);
    },
    callRequestStatementAPI(payload) {
        return apiClient.post("requestStatement", payload);
    },
    callAccountReadAPI(payload) {
        return apiClient.post("accountRead", payload);
    }


};