//import { mapState, mapActions } from 'vuex';
import RoleService from "../../service/RoleService";
import auth from "./auth";
import swal from 'sweetalert2'
import router from "../../routes/router";
export const state = {
    token: null,
    role_loading: false,
    role_btn_display: false,
    role_response: {},
    role_response1: {},
    role: {},
    role_errors: null,
    role_success: null,
    role_success1: null,
    role_read_response: {},
    role_read_response1: {}

}
export const getters = {
    getRoleResponse: state => {
        // console.log("getter getTok")
        return state.role_response
    },

}
export const mutations = {
    updateRoleResponse: (state, payload) => {
        state.role_response = payload
    },
    updateRoleResponse1: (state, payload) => {
        state.role_response1 = payload
    },
    updateRoleReadResponse: (state, payload) => {
        state.role_read_response = payload
    },
    updateRoleReadResponse1: (state, payload) => {
        state.role_read_response1 = payload
    },
    updateRoleBtn: (state, payload) => {
        state.role_btn_display = payload
    },
    updateRoleLoading: (state, payload) => {
        state.role_loading = payload
    },
    updateRoleError: (state, payload) => {
        state.role_errors = payload
    },
    updateRoleSuccess: (state, payload) => {
        state.role_success = payload
    },
    updateRoleSuccess1: (state, payload) => {
        state.role_success1 = payload
    },

}
export const actions = {
    roleRead: ({ commit, dispatch, rootState }, payload) => {
        //commit("updateRoleLoading", true)
        //commit("updateRoleResponse", {});
        commit("updateRoleError", null);
        //commit("updateRoleSuccess", null);
        console.log(payload);
        //console.log(auth.getUserInfo.);
        return RoleService.callRoleReadAPI(payload)
            .then(response => {
                let responseData = response.data;
                commit("updateRoleResponse", responseData);
                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    //commit("updateAuthLoading", false);
                    //commit("updateRoleSuccess", responseData.responseMessage);
                    commit("updateRoleResponse", responseData);
                    //router.push('alternative');
                    //commit("updateRoleLoading", false);
                } else {
                    //commit("updateRoleLoading", false);
                    commit("updateRoleError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                }

            })
            .catch((error) => {
                commit("updateRoleLoading", false);
                commit("updateRoleError", error);
                alert(error);
                // throw error;
                // return Promise.reject(error)
            });
    },
    roleReadSingle: ({ commit, dispatch, rootState }, payload) => {
        commit("updateRoleLoading", true)
        commit("updateRoleReadResponse", {});
        commit("updateRoleError", null);
        commit("updateRoleSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        if (payload.action === 'edit') {
            router.push({ name: 'Edit Role' });
        }
        return RoleService.callRoleReadSingleAPI(payload)
            .then(response => {
                let responseData = response.data;

                if (responseData.responseCode === "00") {
                    console.log(`callRoleCreateAPI>>>>> Message${responseData.responseMessage} Code${responseData.responseCode}`);
                    //commit("updateAuthLoading", false);
                    commit("updateRoleSuccess", responseData.responseMessage);
                    commit("updateRoleReadResponse", responseData);
                    //router.push('alternative');
                    commit("updateRoleLoading", false);
                    commit("updateRoleBtn", false);

                } else {
                    commit("updateRoleLoading", false);
                    commit("updateRoleError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    console.log(`callRoleCreateAPI<<<<<<< Message${responseData.responseMessage} Code${responseData.responseCode}`);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                    commit("updateRoleBtn", true);
                }

            })
            .catch((error) => {
                commit("updateRoleLoading", false);
                commit("updateRoleError", error);
                alert(error);

                // throw error;
                // return Promise.reject(error)
            });
    },
    roleReadSingle1: ({ commit, dispatch, rootState }, payload) => {
        commit("updateRoleLoading", true)
        commit("updateRoleReadResponse1", {});
        commit("updateRoleError", null);
        commit("updateRoleSuccess", null);
        console.log(payload);
        //console.log(payload.username);

        return RoleService.callRoleReadSingleAPI(payload)
            .then(response => {
                let responseData = response.data;

                if (responseData.responseCode === "00") {

                    console.log(`callRoleCreateAPI>>>>> Message${responseData.responseMessage} Code${responseData.responseCode}`);
                    //commit("updateAuthLoading", false);
                    commit("updateRoleSuccess", responseData.responseMessage);
                    commit("updateRoleReadResponse1", responseData);
                    //router.push('alternative');
                    commit("updateRoleLoading", false);

                } else {
                    commit("updateRoleLoading", false);
                    commit("updateRoleError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    console.log(`callRoleCreateAPI<<<<<<< Message${responseData.responseMessage} Code${responseData.responseCode}`);

                }

            })
            .catch((error) => {
                commit("updateRoleLoading", false);
                commit("updateRoleError", error);
                alert(error);

                // throw error;
                // return Promise.reject(error)
            });
    },

    userRolePrivilegeCreate: ({ commit, dispatch, rootState }, payload) => {
        commit("updateRoleLoading", true)
            //commit("updateRoleResponse", {});
        commit("updateRoleError", null);
        commit("updateRoleSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return RoleService.callUserRolePrivilegeCreateAPI(payload)
            .then(response => {
                let responseData = response.data;
                //commit("updateRoleResponse1", responseData);
                if (responseData.responseCode === "00") {
                    console.log(`callRoleCreateAPI>>>>> Message${responseData.responseMessage} Code${responseData.responseCode}`);
                    //commit("updateAuthLoading", false);
                    return RoleService.callRoleReadAPI({
                            readAll: "YES",
                            roleBankCode: payload.rpBankCode
                        })
                        .then(response1 => {
                            let responseData1 = response1.data;

                            if (responseData1.responseCode === "00") {
                                //console.log(responseData.responseMessage);
                                //commit("updateAuthLoading", false);
                                commit("updateRoleSuccess1", responseData1.responseMessage);
                                commit("updateRoleResponse", responseData1);
                                commit("updateRoleBtn", false);
                                //router.push('alternative');
                                commit("updateRoleLoading", false);
                            } else {
                                commit("updateRoleLoading", false);
                                commit("updateRoleError", responseData1.responseMessage);
                                console.log(responseData1.responseMessage);
                                //swal('Error!', responseData.responseMessage, 'error').then();
                            }

                        })
                        .catch((error) => {
                            commit("updateRoleLoading", false);
                            commit("updateRoleError", error);
                            alert(error);
                            // throw error;
                            // return Promise.reject(error)
                        });
                } else {
                    commit("updateRoleLoading", false);
                    commit("updateRoleError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    console.log(`callRoleCreateAPI<<<<<<< Message${responseData.responseMessage} Code${responseData.responseCode}`);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                    commit("updateRoleBtn", true);
                }

            })
            .catch((error) => {
                commit("updateRoleLoading", false);
                commit("updateRoleError", error);
                alert(error);

                // throw error;
                // return Promise.reject(error)
            });
    },

    roleCreate: ({ commit, dispatch, rootState }, payload) => {
        console.log(`*******${JSON.stringify(payload)}`);
        commit("updateRoleLoading", true)
        commit("updateRoleResponse1", {});
        commit("updateRoleError", null);
        commit("updateRoleSuccess", null);
        console.log(payload);
        //console.log(payload.username);
        return RoleService.callRoleCreateAPI(payload)
            .then(response => {
                let responseData = response.data;
                // commit("updateRoleResponse", responseData);
                if (responseData.responseCode === "00") {
                    console.log(responseData.responseMessage);
                    console.log(`callUserRolePrivilegeCreateAPI>>>>> Message${responseData.responseMessage} Code${responseData.responseCode}`);
                    //commit("updateAuthLoading", false);
                    commit("updateRoleResponse1", responseData);
                    commit("updateRoleSuccess", responseData.responseMessage);
                    //router.push('alternative');
                    commit("updateRoleLoading", false);
                    commit("updateRoleBtn", true);
                } else {
                    commit("updateRoleLoading", false);
                    commit("updateRoleError", responseData.responseMessage);
                    console.log(responseData.responseMessage);
                    console.log(`callUserRolePrivilegeCreateAPI<<<<<<<Message${responseData.responseMessage} Code${responseData.responseCode}`);
                    //swal('Error!', responseData.responseMessage, 'error').then();
                    commit("updateRoleBtn", false);
                }

            })
            .catch((error) => {
                commit("updateRoleLoading", false);
                commit("updateRoleError", error);
                // throw error;
                // return Promise.reject(error)
            });
    },
}

export default {
    state,
    actions,
    mutations,
    getters
};